import React, { useState } from 'react'
import AWS from 'aws-sdk'
import { useIntl } from 'react-intl'
import Input from 'src/components/ContactPage/Input'
import SendButton from 'src/components/ContactPage/SendButton'
import Subjects from 'src/components/ContactPage/Subjects'
import Textarea from 'src/components/ContactPage/Textarea'
import Toast from 'src/components/Toast'

import styles from './styles.module.css'

export default ({ callToAction, placeholders, subjects, subjectsTitle }) => {
  const [name, updateName] = useState('')
  const [email, updateEmail] = useState('')
  const [message, updateMessage] = useState('')
  const [subject, updateSubject] = useState('')
  const [error, updateError] = useState(false)

  const intl = useIntl()

  const updateNameValue = event => {
    updateName(event.target.value)
  }

  const updateEmailValue = event => {
    updateEmail(event.target.value)
  }

  const updateMessageValue = event => {
    updateMessage(event.target.value)
  }

  const send = event => {
    event.preventDefault()
    const IdentityPoolId = 'us-east-1:fbabd776-a84e-43a9-a2e8-5cf051cc1af7'
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId,
    })
    AWS.config.update({ region: 'us-east-1' })

    const params = {
      Destination: {
        ToAddresses: ['patrick.genest@mieuxplanifier.com'],
      },
      Message: {
        Body: {
          Html: {
            Charset: 'UTF-8',
            Data: `<h1>FROM ${email} : ${name}</h1>.<br/><h2>${subjects.map(
              sub => sub.value
            )}</h2><br/> ${message}`,
          },
        },
        Subject: {
          Charset: 'UTF-8',
          Data: 'Inquiries',
        },
      },
      Source: 'no-reply@mieuxplanifier.com',
    }

    const sendPromise = new AWS.SES({ apiVersion: '2010-12-01' })
      .sendEmail(params)
      .promise()
    sendPromise
      .then(data => {
        window.location.reload()
      })
      .catch(() => {
        updateError(true)
        setTimeout(() => close(), 5000)
      })
  }

  const close = () => {
    updateError(false)
  }

  return (
    <form onSubmit={send}>
      <Toast
        show={error}
        text={intl.formatMessage({ id: 'error' })}
        close={close}
      />
      <div className={styles.contactPage}>
        <div className={styles.left}>
          <Input
            value={name}
            updateValue={updateNameValue}
            placeholder={placeholders.name.value}
            type={'text'}
          />
          <Input
            value={email}
            updateValue={updateEmailValue}
            placeholder={placeholders.email.value}
            type={'email'}
          />
          <div className={styles.subjectsTitle}>{`${subjectsTitle} :`}</div>
          <Subjects
            subjects={subjects}
            subject={subject}
            updateSubject={updateSubject}
          />
        </div>
        <div className={styles.right}>
          <Textarea
            value={message}
            updateValue={updateMessageValue}
            placeholder={placeholders.message.value}
          />
          <SendButton callToAction={callToAction} />
        </div>
      </div>
    </form>
  )
}
