import React from 'react'

import styles from './styles.module.css'

export default ({ subjects }) => {
  const halfOfsubjectsLength = Math.ceil(subjects.length / 2)
  const subjectsFirstHalf = subjects.slice(0, halfOfsubjectsLength)
  const subjectsSecondHalf = subjects.slice(halfOfsubjectsLength, subjects.length)
  const createOptions = subject => (
    <span
      key={subject.value}
    >
      <label
        className={styles.subject}
        htmlFor={subject.value}
      >
        <input
          type="checkbox"
          name="subject"
          value={subject.value}
          id={subject.value}
        />
        {subject.value}
        <span className={styles.checkmark} />
      </label>
    </span>
  )

  return (
    <div className={styles.subjects}>
      <div className={styles.block}>
        {subjectsFirstHalf.map(createOptions)}
      </div>
      <div className={styles.block}>
        {subjectsSecondHalf.map(createOptions)}
      </div>
    </div>
  )
}

