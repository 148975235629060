import { graphql } from 'gatsby'
import React, { Component } from 'react'
import Layout from 'src/components/Layout'
import Header from 'src/components/Header'
import Contact from 'src/components/ContactPage/Contact'

class ContactPage extends Component {
  render() {
    const {
      pageContext,
      data: { contact },
    } = this.props

    return (
      <Layout pageContext={pageContext}>
        <Header
          title={contact.header.value.title.value}
          description={contact.header.value.description.value}
        />
        <Contact
          callToAction={contact.callToAction.value}
          placeholders={contact.placeholders.value}
          subjects={contact.subject.value}
          subjectsTitle={contact.subjectTitle.value}
        />
      </Layout>
    )
  }
}

export default ContactPage

export const query = graphql`
  query($locale: String) {
    contact: cockpitContact(lang: { eq: $locale }) {
      header {
        value {
          description {
            value
          }
          title {
            value
          }
        }
      }
      callToAction {
        value
      }
      placeholders {
        value {
          email {
            value
          }
          message {
            value
          }
          name {
            value
          }
        }
      }
      subject {
        value {
          value
        }
      }
      subjectTitle {
        value
      }
    }
  }
`
